import { render, staticRenderFns } from "./tokusho.vue?vue&type=template&id=bf4e72be&scoped=true&"
var script = {}
import style0 from "@/assets/css/other.css?vue&type=style&index=0&id=bf4e72be&prod&scoped=true&lang=css&"
import style1 from "@/assets/scss/style.scss?vue&type=style&index=1&id=bf4e72be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf4e72be",
  null
  
)

export default component.exports